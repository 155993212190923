import { io, Socket } from 'socket.io-client';
import { SOCKET_URL } from '../Constants';
interface IGetSocketArgs {
  emitEventName: string;
  resultEventName: string;
  socket: Socket;
  payload: any;
}

interface IGetSocketImageArgs {
  payload: any;
  callback?: (progress:any) => void;
}

export const getBySocket = async ({ emitEventName, resultEventName, socket, payload }: IGetSocketArgs) => {
  return new Promise((resolve) => {
    socket.emit(emitEventName, payload);
    socket.on(resultEventName, (res: any) => {
      console.log('got response ', res)
      resolve(res)
    });
  });
}

export const generateAiImageBySocket = async (args: IGetSocketImageArgs) => {
  const { payload, callback } = args;
  return new Promise(resolve => {
    const socket = createSocket();
    socket.emit('generate-openai-image', payload);
    socket.on('open-ai-image-generated', data => {
      console.log('open ai image response', data);
      if (data.imageUrls?.length ) {
        resolve(data);
      } else if (callback) {
        callback({id: data.id, error: data.error});
      }
    })
  })
}

export const createSocket = () => {
  return io(SOCKET_URL, {
    withCredentials: true
  })
}