import React, { useMemo, useState } from 'react';
import clsx from 'clsx';

import s from './TextArea.module.scss';

interface IProps
  extends Omit<
    React.DetailedHTMLProps<
      React.TextareaHTMLAttributes<HTMLTextAreaElement>,
      HTMLTextAreaElement
    >,
    'onChange'
  > {
  value: string;
  onChange: (newValue: string) => void;
  label: string;
  hasErrors?: boolean;
  height?: number;
  autoComplete?: 'on' | 'off';
  maxLength?: number;
  wrapperClassName?: string;
  wrapperStyle?: React.CSSProperties | undefined;
  textareaRef?: React.LegacyRef<HTMLTextAreaElement>;
}

const TextArea = (props: IProps) => {
  const [error, setError] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    if (props.maxLength !== undefined && value.length > props.maxLength) {
      props.onChange(value.substring(0, props.maxLength));
      setError(true);
    } else {
      setError(false);
      props.onChange(value);
    }
  };

  const wrapperStyle = useMemo(() => {
    let style = {};
    if (props.wrapperStyle) style = { ...props.wrapperStyle };
    if (props.height) style = { ...style, height: `${props.height}px` };

    return style;
  }, [props.height, props.wrapperStyle]);

  const textareaStyle = useMemo(() => {
    let style = {};
    if (props.style) style = { ...props.style };
    if (props.height) style = { ...style, resize: 'none' };

    return style;
  }, [props.height, props.style]);

  return (
    <div
      className={clsx(s.textareaWrapper, props.wrapperClassName)}
      style={wrapperStyle}
    >
      <textarea
        ref={props.textareaRef}
        placeholder={props.label}
        value={props.value}
        onChange={handleChange}
        className={clsx(s.textArea, props.className, {
          [s.error]: props.hasErrors || error,
        })}
        style={textareaStyle}
        autoComplete={props.autoComplete || 'on'}
      />
      {error && props.maxLength && (
        <div className={s.errorText}>Maximum characters {props.maxLength}</div>
      )}
    </div>
  );
};

export default TextArea;
