import { LegacyRef, useEffect, useRef, useState } from 'react';
import { ReactComponent as MoreIcon } from 'Assets/More.svg';

import styles from './OverflowHandler.module.scss';

interface IProps {
  children: React.ReactElement[];
  parentContainerClass: string;
}

const OverflowHandler = ({ children, parentContainerClass }: IProps) => {
  const [showHiddenItem, setShowHiddenItems] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [hiddenItems, setHiddenItems] = useState<any[]>([]);

  const containerRef: LegacyRef<HTMLDivElement> = useRef<any>(null);
  const hiddenItemsRef = useRef(hiddenItems);

  useEffect(() => {
    hiddenItemsRef.current = hiddenItems;
  }, [hiddenItems]);

  useEffect(() => {
    const adjustContent = () => {
      const containers = document.getElementsByClassName(
        parentContainerClass
      ) as HTMLCollectionOf<HTMLElement>;
      if (containers.length === 0 || !containers[0]) return;

      const container = containers[0];
      let containerWidth = container.clientWidth;

      const childNodes = Array.from(
        containerRef.current ? containerRef.current.children : []
      ) as HTMLElement[];

      const tempHiddenItems = [];
      for (let i = 0; i < children.length; i++) {
        if (!childNodes[i]) return;

        childNodes[i].style.display = '';
        containerWidth -= childNodes[i].offsetWidth;

        if (containerWidth - 30 <= 0) {
          childNodes[i].style.display = 'none';
          tempHiddenItems.push(children[i]);
        }
      }

      if (hiddenItemsRef.current.length !== tempHiddenItems.length) {
        setHiddenItems(tempHiddenItems);
        setIsOverflowing(tempHiddenItems.length > 0);
        container.style.position = tempHiddenItems.length > 0 ? 'relative' : '';
        if (tempHiddenItems.length === 0) setShowHiddenItems(false);
      }
    };

    setTimeout(() => {
      adjustContent(); // Initial adjustment
    }, 10);

    window.addEventListener('resize', adjustContent);

    return () => window.removeEventListener('resize', adjustContent);
  }, [children, parentContainerClass]);

  return (
    <>
      <div ref={containerRef} className={styles['overflow-handler']}>
        {children}
      </div>
      {isOverflowing && (
        <span
          className={styles.moreIcon}
          onClick={() => setShowHiddenItems(!showHiddenItem)}
        >
          <MoreIcon />
        </span>
      )}
      {showHiddenItem && (
        <div className={styles.hiddenItemsContainer}>{hiddenItems}</div>
      )}
    </>
  );
};

export default OverflowHandler;
