import React, { useState, useRef, useEffect, FunctionComponent } from 'react';
import TextArea from '../TextArea/TextArea';

import s from './ContentEditableDiv.module.scss';

interface IProps {
  setIsTouched?: (isTouched: boolean) => void;
  onClick?: () => void;
  onChange: (name: string) => void;
  placeholder?: string;
  autofocus?: boolean;
  name: string;
  fontSize: number;
  lineHeight: number;
  fontWeight: number;
  width: number;
  height: number;
  onEdit?: (value: string, pageId?: string, pageEnabled?: boolean) => void;
  allowEmpty?: boolean;
  isMultiline?: boolean;
  blocked?: boolean;
  Icon?: FunctionComponent<React.SVGProps<SVGSVGElement>>;
  onIconClick?: () => void;
}

const ContentEditableDiv = ({
  setIsTouched,
  onChange,
  placeholder,
  autofocus,
  name,
  fontSize,
  lineHeight,
  fontWeight,
  width,
  height,
  onEdit,
  onClick,
  allowEmpty,
  isMultiline,
  blocked,
  Icon,
  onIconClick,
}: IProps) => {
  const [textAreaHeight, setTextAreaHeight] = useState(23);
  const refTextarea = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    getTextAreaHeight();
  }, [refTextarea]);

  const getTextAreaHeight = () => {
    if (refTextarea.current) {
      const lineHeight = parseFloat(
        getComputedStyle(refTextarea.current).lineHeight
      );
      const numberOfLines = Math.floor(
        refTextarea.current.scrollHeight / lineHeight
      );

      setTextAreaHeight(numberOfLines * 22);
    }
  };

  const handleEditing = (
    e: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>
  ) => {
    if (blocked) {
      return;
    }
    if (onEdit) {
      onEdit(e.currentTarget.value);
      e.currentTarget.setSelectionRange(0, 0);
    }
    // Check for an empty value
    if (e.currentTarget.value === '') {
      const emptyText = allowEmpty ? '' : 'Untitled';
      if (onChange) {
        onChange(emptyText);
      }
      if (onEdit) {
        onEdit(emptyText);
        e.currentTarget.setSelectionRange(0, 0);
      }
    }
  };
  if (isMultiline) {
    return (
      <div className={s.containerTextArea}>
        <TextArea
          textareaRef={refTextarea}
          label={placeholder || ''}
          autoFocus={autofocus && true}
          value={name}
          onFocus={() => setIsTouched && setIsTouched(false)}
          maxLength={150}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.currentTarget.blur();
            }
          }}
          onBlur={(e) => {
            handleEditing(e);
          }}
          onClick={(e) => {
            e.stopPropagation();
            if (onClick) onClick();
          }}
          onChange={(value) => {
            if (blocked) {
              return;
            }
            getTextAreaHeight();
            if (onChange) onChange(value);
          }}
          height={textAreaHeight}
          style={{
            width: `${width}px`,
            fontSize: `${fontSize}px`,
            lineHeight: `${lineHeight}px`,
            fontWeight: `${fontWeight}`,
          }}
        />
        {Icon && (
          <Icon
            className={s.icon}
            onClick={() => {
              if (!blocked && onIconClick) onIconClick();
            }}
          />
        )}
      </div>
    );
  } else
    return (
      <div className={s.container}>
        <input
          placeholder={placeholder}
          autoFocus={autofocus && true}
          className={s.name}
          value={name}
          onFocus={() => setIsTouched && setIsTouched(false)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !blocked) {
              e.currentTarget.blur();
            }
          }}
          onBlur={(e) => {
            handleEditing(e);
          }}
          onClick={(e) => {
            e.stopPropagation();
            if (onClick && !blocked) onClick();
          }}
          onChange={(e) => {
            onChange && !blocked && onChange(e.currentTarget.value);
          }}
          style={{
            width: `${width}px`,
            height: `${height}px`,
            fontSize: `${fontSize}px`,
            lineHeight: `${lineHeight}px`,
            fontWeight: `${fontWeight}`,
          }}
        />
        {Icon && (
          <Icon
            className={s.icon}
            onClick={() => {
              if (!blocked && onIconClick) onIconClick();
            }}
          />
        )}
      </div>
    );
}

export default ContentEditableDiv;
