import QUERIES from 'graphql/queries';
import { graphQlCall } from 'graphql/utils';
import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ReactComponent as LogoText } from 'Assets/LogoAndTextWithAi.svg';
import Spinner from 'Components/Common/Spinner/Spinner';
import s from './AiTemplate.module.scss';
import { io, Socket } from 'socket.io-client';
import { PAGECRAFT_API_URL } from 'Constants';
import { rxBlocks, rxIsLoading } from 'rx/rxState';
import { ReactComponent as ArrowRight } from 'Assets/icons/arrow-right.svg';
import { emulatePause, getUserId } from 'utils/api';
import { cloneDeep } from 'lodash';
import { createSocket } from 'utils/socket';

interface IQuestion {
  id: string;
  label: string;
}

interface IAnswer {
  id: string;
  text: string;
}

interface IParams {
  pageId: string;
}

interface IResult {
  id: string;
  type: string;
  text?: string;
  url?: string;
  urls?:string[];
}

interface ISectionSettings {
  aiField: string; 
  content: string;
}

const AiPage = () => {
  const history = useHistory();

  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [sectionSettings, setSectionSettings] = useState<ISectionSettings[][]>([]);
  const [userInput, setUserInput] = useState('');
  const [questionIndex, setQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState<IAnswer[]>([]);
  const [loading, setLoading] = useState(false);
  const [aiResults, setAiResults] = useState<IResult[]>([]);
  const [socketResponse, setSocketResponse] = useState<any | null>(null);
  const [pageBlocks, setPageBlocks] = useState<any[]>([]);
  const [lastId, setLastId] = useState(0);

  const percent = useRef(0);

  const socket = useRef<Socket | null>(null);

  const { pageId } = useParams<IParams>();
  
  useEffect(() => {
    fetchAiPage(pageId);
    fetchPage(pageId);
    socket.current = createSocket();
    socket.current.on("ai-page-generated", data => {
      setSocketResponse(data);
    });
  }, []);

  useEffect(() => {
    if (!socketResponse) {
      return;
    }
    if (socketResponse.progress) {
      percent.current = parseInt(socketResponse.progress);
    }
    if (socketResponse.results) {
      console.log('socketResponse.results:', socketResponse.results)
      const resultsFromAnswers: IResult[] = answers.map(answer => ({
        id: answer.id,
        type: "text",
        text: answer.text,
      }));

      const allResults = [...resultsFromAnswers, ...socketResponse.results];
      setAiResults([...resultsFromAnswers, ...socketResponse.results]);
      const newSections = fillSectionData(pageBlocks, allResults);

      const suggestions: any = {};

      socketResponse.results.map((result: IResult) => {
        if (result.type === 'text') {
          suggestions[result.id] = result.text
        }
      });

      pushDataToEditor({
        lastId: lastId,
        blocks: newSections,
        aiSuggestion: suggestions
      });
      rxIsLoading.next('')
    }
  }, [socketResponse]);

  useEffect(() => {
    if (aiResults.length) {
      percent.current = 0;
      setLoading(false);
    }
  }, [aiResults]);

  const getQuestion = () => {
    const question = questions[questionIndex];
    return question;
  }

  const fetchAiPage = async (pageId: string) => {
    setLoading(true);
    const aiPage = await graphQlCall({
      queryTemplateObject: QUERIES.GET_AI_PAGE,
      values: {
        pageId
      }
    });
    setQuestions(aiPage.questions);
    setLoading(false);
  }

  const setBlockIds = (blocks: any[]) => {
    let currentId = 0;
    const newBlocks =  blocks.map(currentBlock => {
      const { block, lastId } = setIdsInBlock(currentBlock, currentId);
      currentId = lastId;
      return block;
    });
    return { lastId: currentId , blocks: newBlocks };
  }

  const setIdsInBlock = (currentBlock: any, commonLastId: number) => {
    commonLastId++;
    const updatedBlock = cloneDeep(currentBlock);
    updatedBlock.id = commonLastId;
    if (updatedBlock.children?.length) {
      updatedBlock.children = updatedBlock.children.map((child: any) => {
        const { block, lastId } = setIdsInBlock(child, commonLastId);
        commonLastId = lastId;
        return block;
      })
    }
    return { block: updatedBlock, lastId: commonLastId };
  }

  const fetchPage = async (pageId: string) => {
    const page = await fetch(`${PAGECRAFT_API_URL}/pages/load/${pageId}`).then(res => res.json());
    setPageBlocks(page.pages.optin.blocks)
    setLastId(page.pages.optin.lastId)
  }

  const submitQuestion = () => {
    if (!userInput.length) {
      return;
    }
    

    const newAnswers = [...answers];
    newAnswers.push({
      id: getQuestion().id,
      text: userInput,
    });
    setAnswers(newAnswers);
   
    setUserInput('');
    
    if (questionIndex < questions.length - 1) {
      const nextIndex = questionIndex + 1;
      setQuestionIndex(nextIndex);
    } else {
      startGeneratePrompts(newAnswers);
    }
  }

  const startGeneratePrompts = async (answers: IAnswer[]) => {
    if (!socket.current) {
      return;
    }
    setLoading(true);

    const payload: any = {
      pageId,
      questions: answers,
    }

    const userId = getUserId();
    
    if (userId) {
      payload.userId = userId;
    }

    socket.current.emit("generate-ai-page", payload);
    percent.current = 1;

    await emulatePause(1000);
  }

  const fillSectionData = (sectionsData: any[], results: IResult[]): any[] => {
    let newSectionsData: any[] = [];
    sectionsData.forEach((data, sectionIndex) => {
      const newData = setAiFieldsInBlocks(data, results);
      newSectionsData.push(newData);
    })
    return newSectionsData;
  }

  const fillItem = (block: any, results: IResult[]): any => {
    const newBlock = cloneDeep(block);
    if (!newBlock.aiField) {
      return newBlock;
    }
    
    for (const result of results) {
      if (result.type === 'text') {
        if (!newBlock.text?.value?.blocks?.length) {
          console.error('INCORRECT SECTION DATA', newBlock.text);
        } else if (result.id === newBlock.aiField) {
          const textBlock = newBlock.text.value.blocks[0];
          newBlock.text.value.blocks[0] = {
            ...newBlock.text.value.blocks[0],
            inlineStyleRanges:  textBlock.inlineStyleRanges
                                .filter((style:any) => style.offset == 0)
                                .map((style:any) => ({
                                  ...style,
                                  length: result.text!.length,
                                })),
            text: result.text
          };
        }
      } else if (result.type === 'image' && result.id === newBlock.aiField) {
        if(result.urls){
          newBlock.imageUrl = result.urls[0];
        }
      }
    }

    return newBlock;
  }

  const setAiFieldsInBlocks = (data: any, results: IResult[]) => {
    let newData = cloneDeep(data);
    if (newData.aiField) {
      newData = fillItem(data, results);
    }
    if (newData.blocks?.length) {
      newData.blocks = newData.blocks.map((block: any) => {
        if (block.aiField) {
          block = fillItem(block, results);
        }
        return block;
      })
    }
    if (newData.children?.length) {
      newData.children = newData.children.map((child: any) => {
        return setAiFieldsInBlocks(child, results);
      })
    }
    return newData;
  }

  const pushDataToEditor = (newpage: any) => {
    rxBlocks.next(newpage);
    history.push(
      `/edit/edit/unauth/new/optin?generated=1`
    );
  }

  return (
    <div className={s.mainContent}>
      <div className={s.logo}>
        <LogoText />
      </div>
      {loading && <div>
        <Spinner size={200} />
        {percent.current > 0 && <div className={s.percent}>
          {percent.current} %
        </div>}
      </div>}
      {!loading && questions.length && <section>
        <div className={s.inputBlock}>
          <label>{getQuestion().label}</label>
          <input type="text" value={userInput} onChange={e => setUserInput(e.target.value)} />
        </div>
        <div className={s.buttonBlock}>
          { questionIndex < questions.length - 1 ? 
          <button onClick={() => submitQuestion()}>
            Next
            <ArrowRight className={s.icon} />
          </button>
          :
          <button onClick={() => submitQuestion()} className={s.generate}>
            Generate
          </button>
          }
        </div>
      </section>}
    </div>
  );
}

export default AiPage;