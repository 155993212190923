import { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import { IBookChapter } from '../types';
import { ReactComponent as Close } from '../../../Assets/closeIcon.svg';
import { ReactComponent as Pencil } from '../../../Assets/pencil.svg';
import { ReactComponent as ThreeDots } from '../../../Assets/threeDots.svg';
import { ReactComponent as ChapterAdd } from '../../../Assets/chapterAdd.svg';
import { ReactComponent as Regenerate } from '../../../Assets/chapterRegenerate.svg';
import { ReactComponent as ChapterDelete } from '../../../Assets/chapterDelete.svg';
import { ReactComponent as ChapterRegenerate } from '../../../Assets/chapterRegenerate.svg';
import { ReactComponent as ChapterDragAndDrop } from '../../../Assets/chapterDragAndDrop.svg';

import { rxActiveChapterIndex } from 'rx/rxState';
import ContentEditableDiv from '../ContentEditableDiv/ContentEditableDiv';
import DropdownBox from 'Components/Common/dropdownBox/DropdownBox';
import LinkButton from 'Components/Common/LinkButton/LinkButon';
import Spinner from 'Components/Common/Spinner/Spinner';

import s from './ChapterItemEditor.module.scss';

interface IProps {
  chapter: IBookChapter;
  index: number;
  onChapterTitleEditedProps: (value: string, index: number) => void;
  activeChapter: number;
  setActiveChapter: (index: number) => void;
  deleteChapter: (index: number) => void;
  onChapterAdd: (indexBefore: number, action: 'add' | 'insert') => void;
  isBookTitleActive: boolean;
  setIsBookTitleActive: (arg: boolean) => void;
  onChapterRegenerate: (index: number) => void;
  onChapterNotesRegenerate: (index: number, notes: string[]) => void;
  addingChapterIndex: null | number;
  blocked: boolean;
  dragableData: any;
}

function ChapterItemEditor({
  chapter,
  index,
  onChapterTitleEditedProps,
  activeChapter,
  setActiveChapter,
  deleteChapter,
  onChapterAdd,
  isBookTitleActive,
  setIsBookTitleActive,
  onChapterRegenerate,
  onChapterNotesRegenerate,
  addingChapterIndex,
  blocked,
  dragableData,
}: IProps) {
  const isGeneratedAllBookEnd = activeChapter !== null;
  const [chapterTitleInput, setChapterTitleInput] = useState<string>(
    chapter.title
  );
  const [isDotsMenuOpen, setIsDotsMenuOpen] = useState<boolean>(false);
  const [hasCurrentChapterActive, setHasCurrentChapterActive] = useState(false);
  const [chapterNotesEditVisible, setChapterNotesEditVisible] = useState(false);
  const [chapterNotes, setChapterNotes] = useState(
    chapter.comments.length > 0 ? chapter.comments[0] : undefined
  );

  const refDotsMenu = useRef(null);

  useEffect(() => {
    setChapterTitleInput(chapter.title);
  }, [chapter]);

  useEffect(() => {
    setHasCurrentChapterActive(index === activeChapter);
  }, [index, activeChapter]);

  const handleDotsMenuOpen = () => {
    onChapterClick();
    setIsDotsMenuOpen(true);
  };

  const onChapterTitleEdited = (value: string) => {
    onChapterTitleEditedProps(value, index);
  };

  const onChapterClick = () => {
    if (isGeneratedAllBookEnd) {
      rxActiveChapterIndex.next(index + 1);
    }
  };

  const handleCancelEditNotes = () => {
    setChapterNotesEditVisible(false);
    setChapterNotes(
      chapter.comments.length > 0 ? chapter.comments[0] : undefined
    );
  };

  const handleRegenerateChapterNotes = () => {
    setChapterNotesEditVisible(false);
    if (chapter.comments.length > 0 && chapter.comments[0] === chapterNotes)
      return;

    if (chapterNotes !== undefined)
      onChapterNotesRegenerate(index, [chapterNotes]);
  };

  return (
    <div
      className={clsx(s.chapterItemWrapperEditVersion, {
        [s.chapterItemWrapperEditVersionActive]:
          index === activeChapter && !isBookTitleActive,
      })}
    >
      <div
        className={clsx(s.chapterItemEditVersion, {
          [s.chapterItemActiveEditVersion]:
            index === activeChapter && !isBookTitleActive,
        })}
        onClick={onChapterClick}
      >
        <div
          className={clsx(
            s.chapterIndexCircle,
            s.chapterIndexCircleEditVersion,
            {
              [s.chapterIndexCircleNoneDisabled]: !isGeneratedAllBookEnd,
              [s.chapterIndexCircleGenerated]: !isGeneratedAllBookEnd,
              [s.chapterIndexCircleGeneratedEnd]: isGeneratedAllBookEnd,
            }
          )}
        >
          {addingChapterIndex === index ? <Spinner size={25} /> : index + 1}
        </div>
        <div className={s.chapterTitleInputEditVersion}>
          <ContentEditableDiv
            name={chapterTitleInput}
            fontSize={14}
            lineHeight={20}
            fontWeight={500}
            width={180}
            height={40}
            onClick={onChapterClick}
            onChange={!isGeneratedAllBookEnd ? () => {} : setChapterTitleInput}
            onEdit={!isGeneratedAllBookEnd ? () => {} : onChapterTitleEdited}
            isMultiline={true}
            blocked={blocked}
          />
        </div>
        <div {...dragableData.dragHandleProps}>
          <ChapterDragAndDrop className={s.chapterDragAndDropSvgEditVersion} />
        </div>
        <ThreeDots
          className={s.threeDotsSvgEditVersion}
          ref={refDotsMenu}
          onClick={() => {
            if (!blocked) handleDotsMenuOpen();
          }}
        />
        <DropdownBox
          isOpen={isDotsMenuOpen && hasCurrentChapterActive}
          onClose={() => setIsDotsMenuOpen(false)}
          pointerEvents={true}
          componentRef={refDotsMenu}
          height={70}
        >
          <div className={s.dotsMenuBlock}>
            <div
              className={clsx({ [s.cursorDisabled]: blocked })}
              onClick={() => {
                if (!blocked) {
                  onChapterRegenerate(index);
                  setIsDotsMenuOpen(false);
                }
              }}
            >
              <ChapterRegenerate /> Regenerate
            </div>
            <div
              className={clsx({ [s.cursorDisabled]: blocked })}
              onClick={() => {
                if (!blocked) {
                  setChapterNotesEditVisible(true);
                  setIsDotsMenuOpen(false);
                }
              }}
            >
              <Pencil /> Edit Notes
            </div>
            <div
              className={clsx({ [s.cursorDisabled]: blocked })}
              onClick={() => {
                if (!blocked) {
                  onChapterAdd(index, 'insert');
                  setIsDotsMenuOpen(false);
                }
              }}
            >
              <ChapterAdd /> Add New Above
            </div>
            <div
              className={clsx({ [s.cursorDisabled]: blocked })}
              onClick={() => {
                if (!blocked) {
                  onChapterAdd(index + 1, 'insert');
                  setIsDotsMenuOpen(false);
                }
              }}
            >
              <ChapterAdd /> Add New Below
            </div>
            <div
              className={clsx({ [s.cursorDisabled]: blocked })}
              onClick={() => {
                if (!blocked) {
                  deleteChapter(index);
                  setIsDotsMenuOpen(false);
                }
              }}
            >
              <ChapterDelete /> Delete
            </div>
          </div>
        </DropdownBox>
      </div>
      {chapterNotesEditVisible && (
        <div className={s.notesContainer}>
          <textarea
            className={s.textarea}
            placeholder="Notes"
            value={chapterNotes}
            onChange={(e) => setChapterNotes(e.target.value)}
          />
          <div className={s.actionButtons}>
            <LinkButton
              color="secondary"
              className={s.button}
              onClick={handleCancelEditNotes}
            >
              <Close /> Cancel
            </LinkButton>
            <LinkButton
              color="secondary"
              className={s.button}
              onClick={handleRegenerateChapterNotes}
            >
              <Regenerate /> Regenerate
            </LinkButton>
          </div>
        </div>
      )}
    </div>
  );
}

export default ChapterItemEditor;
