import { useState, useEffect, useRef } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import { Colors } from '../TextEditor/StyleMaps';

import styles from './ColorSelector.module.scss';

interface IProps {
  color: string;
  onChange: (value: string) => void;
  onSelectorToggle?: (value: boolean) => void;
}

const ColorSelector = (props: IProps) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [selectedColor, setColor] = useState(props.color);

  const selectionTimeout = useRef<NodeJS.Timeout>();

  const onColorClick = () => {
    setDisplayColorPicker(!displayColorPicker);
    if (props.onSelectorToggle) props.onSelectorToggle(true);
  };

  const onClose = () => {
    setDisplayColorPicker(false);
    if (props.onSelectorToggle) props.onSelectorToggle(false);
  };

  useEffect(() => {
    if (props.color != selectedColor || props.color != selectedColor) {
      setColor(props.color);
    }
  }, [props.color]);

  // const applyColor = useMemo(() => {
  //   return debounce((color) => {
  //     props.onChange(color);
  //   }, 50);
  // }, [props.onChange])

  const changeColorByPicker = (value: ColorResult) => {
    setColor(value.hex);
    props.onChange(value.hex);
    // applyColor(value.hex);
  };

  const handleChangeComplete = () => {
    if (selectionTimeout.current) {
      clearTimeout(selectionTimeout.current);
    }

    selectionTimeout.current = setTimeout(() => {
      onClose();
    }, 1000);
  };

  return (
    <>
      <div
        className={styles.viewBlock}
        onMouseDown={(e) => e.preventDefault()}
        onClick={onColorClick}
        style={{ background: selectedColor }}
      />
      {displayColorPicker && (
        <div className={styles.popover}>
          <div className={styles.cover} onClick={onClose} />
          <SketchPicker
            width="188px"
            disableAlpha
            presetColors={Colors}
            onChange={changeColorByPicker}
            onChangeComplete={handleChangeComplete}
            color={selectedColor}
          />
        </div>
      )}
    </>
  );
};

export default ColorSelector;
